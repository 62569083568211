
import Vue from 'vue'
import EmailSMSConfirmPopupChunk from '@whitelist/wl-old-library/components/popupChunks/emailSMSConfirm.popup-chunk.vue'
import EmailGAConfirmPopupChunk from '@whitelist/wl-old-library/components/popupChunks/emailGAConfirm.popup-chunk.vue'
import YubikeyConnectionPopupChunk from '@whitelist/wl-old-library/components/popupChunks/YubikeyConnection.popup-chunk.vue'
import { StepByStepGlossary } from '@/constants/services'
import YubikeyAuthMixin from '@/mixins/YubikeyAuthMixin'
import { emailAllowedSymbolsRegExp } from '@/constants/baseConstatnts'

export default Vue.extend({
  name: 'PasswordRepair',
  mixins: [YubikeyAuthMixin],
  data: () => ({
    showVerification: false,
    verificationComponent: 'EmailSMSConfirmPopupChunk',
    verificationData: null,
    sessionId: '',
    yubikeyLetRetry: false,
    form: {
      valid: false,
      fields: {
        email: {
          value: '',
          rules: [
            (v: string) => Boolean(v.length) || 'passwordRepair.errors.required',
            (v: string) => emailAllowedSymbolsRegExp.test(v) || 'passwordRepair.errors.emailIncorrect'
          ]
        }
      },
      responseErrors: [],
      isLoading: false
    }
  }),
  methods: {
    onCancel () {
      const vm = this as any
      vm.$router.replace({ name: 'SignIn' })
    },

    async authByYubikey () {
      const vm = this as any
      vm.YubikeyAuth(StepByStepGlossary.resetPassword, null, async (authResponse: any) => {
        if (authResponse.success) {
          vm.$store.commit('popupModule/setClosed')
          vm.$router.replace({ name: 'PasswordRepairSetup', query: { an3K8QkkJb3Mzz: vm.sessionId } })
          return
        }
        vm.form.responseErrors = authResponse.data?.errors ?? [vm.$t('common.errors.cantDoOperation')]
        vm.yubikeyLetRetry = true
      }, vm.sessionId, vm.authByYubikey)
    },

    async validate (): Promise<void> {
      const vm = this as any
      this.form.isLoading = true
      const email = this.form.fields.email.value
      const initRepairResponse = await vm.$services.authorization.repair.initRepair({ email })
      this.form.isLoading = false

      const codes = initRepairResponse.data.confirmations
      this.sessionId = initRepairResponse.data.session_id

      if (!initRepairResponse.success) {
        vm.form.responseErrors = initRepairResponse.data.errors
        return
      }

      this.showVerification = true

      const isVerificationYubikey = 'yubikey' in codes
      if (isVerificationYubikey) {
        vm.verificationComponent = 'YubikeyConnectionPopupChunk'
        vm.verificationData = {
          additionalPopupContent: 'Подтверждение операции по Yubikey'
        }
        vm.authByYubikey()
        return
      }

      vm.verificationComponent = 'ga' in codes ? 'EmailGAConfirmPopupChunk' : 'EmailSMSConfirmPopupChunk'

      vm.verificationData = {
        codes,
        sessionId: this.sessionId,
        name: 'resetPassword',
        endpointName: StepByStepGlossary.validateSession,
        sendCodeMethod: 'passwordResetSendCode',
        steps: 3
      }
    }
  },
  // eslint-disable-next-line vue/no-unused-components
  components: { EmailSMSConfirmPopupChunk, EmailGAConfirmPopupChunk, YubikeyConnectionPopupChunk }
})
